import Head from "next/head";
import Nav from "../components/Nav";
import Hero from "../components/Hero";
import Solutions from "../components/solutions";
import Partners from "../components/partners";
import Team from "../components/Team";
import Footer from "../components/Footer";
import Numbers from "../components/Numbers";
import AppScreen from "../components/AppScreen";
import Contact from "../components/Contact";
import Banner from "../components/Banner";

export default function Home() {
  return (
    <div>
      <Head>
        <title>Research on Mobile</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Banner />
      <Nav />
      <Hero />
      <Solutions />
      <Partners />
      <Numbers />
      <AppScreen />
      <Team />
      <Contact />
      <Footer />
    </div>
  );
}
