import React from "react";
import Slider from "react-slick";
import Image from "next/image";
import styles from "./Team.module.css";

import nader from "../../public/Nader-profile.jpg";
import ebrahim from "../../public/Ebrahim-profile.jpg";
import ehsan from "../../public/Ehsan-profile.jpg";
import farzad from "../../public/Farzad-profile.jpg";
import forough from "../../public/Forough-profile.jpg";
import hamidreza from "../../public/Hamidreza-profile.jpg";
import fateme from "../../public/Fateme-profile.jpeg";
import zahra from "../../public/Zahra-profile.jpg";

const TeamCard = ({ name, title, link, img }) => {
  return (
    <div className="flex flex-col align-middle justify-center bg-white p-3 lg:w-48 lg:h-64 md:h-48 md:w-40 h-60 w-40 shadow rounded-lg mx-auto">
      <a href={link}>
        <div
          className={
            styles.imageWrapper +
            ` ` +
            "shadow-lg border border-gray-200 rounded-full h-24 w-24 lg:h-32 lg:w-32 mx-auto"
          }
        >
          <Image
            width={160}
            height={160}
            src={img}
            alt=""
            layout="responsive"
            quality={100}
          />
        </div>

        <h2 className=" mt-2 lg:mt-4 font-bold lg:text-base md:text-sm text-xs text-center">
          {name}
        </h2>
        <h6 className="mt-1 lg:mt-2 text-xs lg:text-sm font-medium text-center">
          {title}
        </h6>
      </a>
    </div>
  );
};

export default function Team() {
  const teamMembers = [
    {
      name: "Nader Kobeissi",
      title: "Co-Founder & CEO",
      link: "https://www.linkedin.com/in/nader-kobeissi-940547",
      img: nader,
    },
    {
      name: "Ebrahim Eskandari",
      title: "Co-Founder & COO",
      link: "https://www.linkedin.com/in/eeskandari/",
      img: ebrahim,
    },
    {
      name: "Ehsan Ahmadi",
      title: "Co-Founder & CTO",
      link: "https://www.linkedin.com/in/ehsanahgh/",
      img: ehsan,
    },
    {
      name: "Farzad Haghighat",
      title: "Software Engineer",
      link: "https://www.linkedin.com/in/farzad-haghighat",
      img: farzad,
    },
    {
      name: "Forough Fallah",
      title: "Software Developer",
      link: "https://www.linkedin.com/in/forough-fallah/",
      img: forough,
    },
    {
      name: "Hamidreza Mahdavipanah",
      title: "Software Engineer",
      link: "https://www.linkedin.com/in/mahdavipanah/",
      img: hamidreza,
    },
    {
      name: "Fateme Shojaei",
      title: "Software Developer",
      link: "https://www.linkedin.com/in/fatemeh-shojaei-3929aa18a/",
      img: fateme,
    },
    {
      name: "Zahra Dokhaie",
      title: "Data Analyst",
      link: "https://www.linkedin.com/in/zahra-dokhaie/",
      img: zahra,
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    // adaptiveHeight: true,
    className: "flex",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className=" bg-dark-pattern" id="team">
      <div className="px-6 sm:px-20 lg:px-0 md:px-32 2xl:w-2/3 mx-auto py-8">
        <h4 className="text-center text-3xl md:text-4xl font-semibold py-8 text-white">
          Our Team
        </h4>
        <div className="px-4 md:px-8 lg:px-32">
          <Slider {...sliderSettings}>
            {teamMembers.map((tm, index) => {
              return (
                <TeamCard
                  key={index}
                  name={tm.name}
                  title={tm.title}
                  link={tm.link}
                  img={tm.img}
                />
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
}
